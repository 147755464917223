@use '../../app/app.scss';

.loader {
    width: 50px;
    display: grid;
    aspect-ratio: 1;
    border: 4px solid #0000;
    border-radius: 50%;
    border-right-color: app.$dark-blue-color;
    animation: infiniteRotating 1s infinite linear;
    margin-top: 35vh;

    &::before, &::after {
        content: "";
        grid-area: 1/1;
        margin: 2px;
        border: inherit;
        border-radius: 50%;
        animation: infiniteRotating 2s infinite;
    }

    &::after {
        margin: 8px;
        animation-duration: 3s;
    }

    @keyframes infiniteRotating { 
        100% {
            transform: rotate(1turn)
        }
    }
}


.patientMain {
    width: 95%;
    height: max-content;
    padding: 0px 0px 50px 0px;

    .sectionNavigation {
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        gap: 30px;
        padding: 0px 20px;
        margin-bottom: 70px;

        .sectionNavigation__element {
            display: flex;
            flex-grow: 1;
            flex-basis: 130px;
            justify-content: center;
            align-items: center;
            text-align: center;
            list-style: none;
            padding: 15px 20px;
            border: 2px solid app.$dark-blue-color;
            border-radius: 10px;
            font-family: app.$basic-font-family;
            font-size: clamp(0.5rem, 1.4vw, 1rem) !important;      
            -webkit-user-select: none;
            user-select: none;
            transition: 0.2s all ease-in-out;

            &:hover {
                cursor: pointer;
                transform: scale(1.03);
            }

            @media only screen and (max-width: 700px) {
                & {
                    font-size: clamp(0.8rem, 1.5vw, 1.3rem) !important;  
                }
            }

            &_less_than_1150px {
                display: none;
                flex-grow: 1;
                flex-basis: 130px;
                justify-content: center;
                align-items: center;
                text-align: center;
                list-style: none;
                padding: 15px 20px;
                border: 2px solid app.$dark-blue-color;
                border-radius: 10px;
                font-family: app.$basic-font-family;
                font-size: clamp(0.5rem, 1.4vw, 1rem) !important;      
                -webkit-user-select: none;
                user-select: none;
                transition: 0.2s all ease-in-out;

                &:hover {
                    cursor: pointer;
                    transform: scale(1.03);
                }

                @media only screen and (max-width: 700px) {
                    & {
                        font-size: clamp(0.8rem, 1.5vw, 1.3rem) !important;  
                    }
                }

                @media only screen and (max-width: 1150px) {
                    display: flex;
                }
            }
        }
    }

    .dataSectionTitle {
        font-family: app.$basic-font-family;
        font-size: clamp(0.9rem, 1.8vw, 1.5rem) !important;
    }

    .dataSectionDividerLineStart {
        border-color: app.$dark-blue-color;
        margin-bottom: 50px;
    }

    .dataSectionDividerLineEnd {
        border-color: app.$dark-blue-color;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .personalDataWrapper, .medicalDataWrapper, .patientAvailableTestsWrapper, .patientCompletedTestsWrapper {
        display: flex;
        gap: 50px;
        flex-wrap: wrap;
        justify-content: center;
        font-family: app.$basic-font-family;
        padding: 0px 40px;

        .patientDataWrapper, .patientDoctorDataWrapper { 
            width: max-content;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            flex-grow: 1;
        
            .patientDataLabel, .patientDoctorDataLabel {
                width: max-content;
                max-width: 100%;
                text-align: center;
                padding: 0px 20px;
                color: grey;
                font-size: clamp(0.8rem, 1.6vw, 1.3rem) !important;

                @media only screen and (max-width: 550px) {
                    & {
                        max-width: 80%;
                    }
                }
            }
            
            .patientDataBox, .patientDoctorDataBox {
                display: flex;
                min-width: 150px;
                height: 100%;
                padding: 10px 20px;
                background-color: app.$white-color;
                border-radius: 10px;
                border: 3px solid app.$dark-blue-color;
                font-size: clamp(0.8rem, 1.6vw, 1.3rem) !important;
            }
        }        
    }

    .patientDataTogetherWrapper {
        width: 100%;
        display: flex;
        gap: 50px;
        flex-wrap: wrap;
        justify-content: center;
        font-family: app.$basic-font-family;
    }

    .accoutActionsSectionTitle {
        display: none;
        font-family: app.$basic-font-family;
        font-size: clamp(0.9rem, 1.8vw, 1.5rem) !important;
        margin-top: 70px;

        @media only screen and (max-width: 1150px) {
            display: block;
        }
    }

    .accoutActionsDividerLineStart {
        display: none;
        border-color: app.$dark-blue-color;
        margin-bottom: 50px;

        @media only screen and (max-width: 1150px) {
            display: block;
        }
    }

    .logOutButton {
        display: none;
        padding: 10px 15px;
        font-size: clamp(1rem, 1vw, 1.8rem) !important;
        color: red;
        border: 2px solid red;
        border-radius: 5px;
        transition: 0.2s all ease-in-out;

        &:hover {
            cursor: pointer;
            transform: scale(1.05);
        }

        @media only screen and (max-width: 1150px) {
            display: block;
        }
    }
}
