@use '../../app/app.scss';

a {
    text-decoration: none;
    color: inherit;
}

.header_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: max-content;
    padding: 20px 0px;
    font-family: app.$basic-font-family;
    position: relative;

    .navigation_box {
        width: max-content;
        height: max-content;

        .navbar {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 30px;
            padding: 0 20px;

            .navbar__element {
                flex-grow: 1;
                padding: 10px 20px;
                list-style: none;
                color: app.$dark-font-color;
                font-size: clamp(0.8rem, 1.6vw, 1.3rem) !important;
                border: 3px solid app.$dark-blue-color;
                border-radius: 5px;
                transition: 0.2s all ease-in-out;

                &:hover {
                    cursor: pointer;
                    transform: scale(1.05);
                }

                &.active {
                    background-color: app.$dark-blue-color;
                    color: app.$white-font-color !important;
                    transform: scale(1.1);
                }

                @media only screen and (max-width: 600px) {
                    &.active {
                        transform: scale(1);
                    }
                }

                @media only screen and (max-width: 600px) {
                    & {
                        font-size: clamp(0.9rem, 1.6vw, 1.4rem) !important;
                    }
                }
            }
        }

        @media only screen and (max-width: 600px) {
            .navbar {
                gap: 10px;
            }
        }
    }

    .logOutButton {
        display: block;
        position: absolute;
        width: clamp(30px, 2.3vw, 40px) !important;
        height: clamp(30px, 2.3vw, 40px) !important;
        transition: 0.2s all ease-in-out;
        left: 95%;

        &:hover {
            cursor: pointer;
            transform: scale(1.05);
        }

        @media only screen and (max-width: 1150px) {
            display: none;
        }
    }
}
