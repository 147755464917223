@use '../../app/app.scss';

.adminAddPatientMenuBox {
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: clamp(350px, 30%, 40%);
    max-height: 90vh !important;
    background-color: app.$white-color;
    border: 3px solid app.$dark-blue-color;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    font-family: app.$basic-font-family;
    font-size: clamp(0.7rem, 1.6vw, 1.3rem);
    overflow-y: auto;
    padding: 20px;
    overflow-x: hidden;

    .adminAddPatientMenuHeaderBox {
        width: 100%;
        height: max-content;
        padding: 10px;
    }

    .closeAdminAddPatientMenuButton {
        transition: 0.2s all ease-in-out;

        &:hover {
            cursor: pointer;
            transform: scale(1.1);
        }
    }

    .patientDataInputsList {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 100%;
    }

    .patientDataInputsWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .patientDataLabel {
            color: grey;
            width: calc(80% - 40px);
            max-width: 100%;
            text-align: center;
            margin-bottom: 5px;
            font-size: clamp(0.9rem, 1.3vw, 1.3rem) !important;
        }

        .patientDataInput {
            width: calc(80% - 40px);
            padding: 5px 10px;
            font-family: app.$basic-font-family;
            border: 2px solid app.$dark-blue-color;
            border-radius: 5px;
            background-color: app.$white-color;
            color: app.$dark-font-color;
        }

        .patientDataSelect {
            width: calc(80% - 16px);
            padding: 5px 10px;

            font-family: app.$basic-font-family;
            border: 2px solid app.$dark-blue-color;
            border-radius: 5px;
            background-color: app.$white-color;
            color: app.$dark-font-color;
        }

        .patientDataCheckBoxesWrapper {
            max-width: 90% !important;
            border: 2px solid transparent;
            padding: 25px;
            font-family: app.$basic-font-family;
            font-size: clamp(0.9rem, 1.5vw, 1.3rem);
        }    
    }

    .addPatientButton {
        display: block;
        padding: 10px 15px;
        background-color: app.$white-color;
        border-radius: 5px;
        border: 3px solid green;
        color: green;
        font-size: clamp(0.7rem, 1.2vw, 1rem) !important;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0 !important;
    }

    .errorMessage {
        display: block;
        max-width: 80%;
        text-align: center;
        color: red;
        font-size: clamp(0.6rem, 1.1vw, 0.9rem) !important;
        margin-top: 0;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
    }  
}

.not-selected {
    border: 2px solid red !important;
}
