.adminPage {
    width: 100%;
    height: max-content;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.navbar-bg{
    background-color: #BFDBF7;
}
.btn-color-add-patient{
    background-color: #3DA185;
}