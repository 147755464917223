@use '../app/app.scss';

.loginPage {
    width: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background:
            radial-gradient(
                            circle at 6.7% 75%,
                            rgb(0 0 255 / 20%),
                            rgb(0 0 255 / 0%) 20%
            ),
            radial-gradient(
                            circle at 90% 5%,
                            rgb(0 0 255 / 20%),
                            rgb(0 0 255 / 0%) 25%
            ),
            linear-gradient(to right, rgb(0 70 133 / 10%), rgb(220 236 251 / 100%));


    .loginWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

    }
    
    .logoImage {
        width: clamp(130px, 12vw, 15vw);
        }
    
    .login_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: max-content;
        padding: 50px clamp(30px, 5vw, 50px) 60px clamp(30px, 5vw, 70px);        background-color: app.$white-color;
        font-family: app.$basic-font-family;
        font-size: clamp(1rem, 1.4rem, 2vw);
        border-radius: 7px;

        .loginForm {
            display: flex;
            flex-direction: column;
            input {
                outline: 0;
            }
            .btn-shadow{
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            }
            .input-icon{
                height: 25px;
            }
        
            select {
                margin-top: 10px;
                border: 0;
                outline: 0;
                width: 100%;
                padding: 0.5rem 1.5rem 0.5rem 0.5rem;
                color: app.$dark-font-color;
                border-radius: 0.2em;
                box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
                cursor: pointer;
                font-size: 1em;
                font-family: inherit;
        
                &:focus {
                    outline: none;
                }
        
                option {
                    color: inherit;
                }
            }
        
            .submitButton {


                transition: 0.2s all ease-in-out;
    
                &:hover {
                    cursor: pointer;
                    transform: scale(1.05);
                }
            }
        }
    
        .loginErrorMessage {
            margin-top: 50px;
            font-size: 0.8em;
            color: red;
            max-width: 214px;
            text-align: center;
        }
    }
}
