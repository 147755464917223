@use '../../app/app.scss';

.dataEditorLoader {
    display: grid;
    width: 50px;
    aspect-ratio: 1;
    border: 4px solid #0000;
    border-radius: 50%;
    border-right-color: app.$dark-blue-color;
    animation: infiniteRotating 1s infinite linear;
    margin-top: calc(30% - 30px);
    margin-left: calc(50% - 35px);
    margin-bottom: 100px;

    &::before, &::after {
        content: "";
        grid-area: 1/1;
        margin: 2px;
        border: inherit;
        border-radius: 50%;
        animation: infiniteRotating 2s infinite;
    }

    &::after {
        margin: 8px;
        animation-duration: 3s;
    }

    @keyframes infiniteRotating { 
        100% {
            transform: rotate(1turn)
        }
    }
}

.patientDataEditorBox {
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: clamp(350px, 30%, 40%);
    max-height: 90vh !important;
    background-color: app.$white-color;
    border: 3px solid app.$dark-blue-color;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    font-family: app.$basic-font-family;
    font-size: clamp(0.7rem, 1.6vw, 1.3rem);
    overflow-y: auto;
    padding-bottom: 30px;
    overflow-x: hidden;

    .patientDataEditorHeaderBox {
        width: 100%;
        height: max-content;
        padding: 10px;
    }

    .closePatientDataEditorButton {
        transition: 0.2s all ease-in-out;

        &:hover {
            cursor: pointer;
            transform: scale(1.1);
        }
    }

    .patientDataInputsList {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 100%;
    }

    .patientDataInputsWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .patientDataLabel {
            color: grey;
            width: calc(80% - 40px);
            max-width: 100%;
            text-align: center;
            margin-bottom: 5px;
            font-size: clamp(0.9rem, 1.3vw, 1.3rem) !important;
        }

        .patientDataInput {
            width: calc(80% - 40px);
            padding: 5px 10px;
            font-family: app.$basic-font-family;
            border: 2px solid app.$dark-blue-color;
            border-radius: 5px;
            background-color: app.$white-color;
            color: app.$dark-font-color;
        }

        .patientDataSelect {
            width: calc(80% - 16px);
            padding: 5px 10px;

            font-family: app.$basic-font-family;
            border: 2px solid app.$dark-blue-color;
            border-radius: 5px;
            background-color: app.$white-color;
            color: app.$dark-font-color;
        }

        .patientDataCheckBoxesWrapper {
            max-width: 100%;
            border: 2px solid transparent;
            padding: 25px;
        }   
    }

    .patientDataSaveButton {
        height: max-content;
        width: max-content;
        padding: 15px 20px 13px 20px;
        color: green;
        border: 3px solid green;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        transition: 0.2s all ease-in-out;

        &:hover {
            cursor: pointer;
            transform: scale(1.05);
        }
    }

    .errorMessage {
        display: block;
        max-width: 80%;
        text-align: center;
        color: red;
        font-size: clamp(0.6rem, 1.1vw, 0.9rem) !important;
        margin-top: 0;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
    }  
}
